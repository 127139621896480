@font-face {
  font-family: "Michroma";
  src: url(../fonts/Michroma-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Robo";
  src: url(../fonts/Roboto-Thin.ttf);
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Robo";
  src: url(../fonts/Roboto-ThinItalic.ttf);
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Robo";
  src: url(../fonts/Roboto-Light.ttf);
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Robo";
  src: url(../fonts/Roboto-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Robo";
  src: url(../fonts/Roboto-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Robo";
  src: url(../fonts/Roboto-Italic.ttf);
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Robo";
  src: url(../fonts/Roboto-Medium.ttf);
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Robo";
  src: url(../fonts/Roboto-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Robo";
  src: url(../fonts/Roboto-Bold.ttf);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Robo";
  src: url(../fonts/Roboto-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Robo";
  src: url(../fonts/Roboto-Black.ttf);
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Robo";
  src: url(../fonts/Roboto-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
.bigNumber {
  @apply text-6xl mr-3 bg-clip-text bg-gradient-to-b from-secondary to-primary text-transparent font-bold;
}

.gradButton {
  @apply text-base-100 btn btn-primary;
  border: none;
  font-family: "Michroma";
  transition: all 0.5s;
}
.gradButton:hover {
  @apply text-base-100 btn-secondary;
  transition: all 0.5s;
}

.wideList {
  @apply flex flex-col gap-5;
}
.wideList .companyList .location {
  width: 200px;
}
.wideList .companyList .industry {
  width: 200px;
}

.numberCircle {
  @apply bg-primary text-base-100 absolute top-2 right-5 font-bold;
  height: 3rem;
  width: 3rem;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listCard,
.skillCard {
  @apply bg-white w-full p-5 rounded-md shadow-sm shadow-accent/15 text-black/60 relative;
}

table {
  border-collapse: separate;
  border-spacing: 0 1rem;
}
table thead th {
  @apply text-primary border-b-2 border-primary font-normal pb-3 text-left;
  font-family: "Michroma";
}
table tbody tr {
  @apply shadow-sm shadow-accent/15 rounded-lg bg-white text-primary overflow-hidden;
}
table tbody td {
  @apply px-3 py-5;
}
table tbody td:first-of-type {
  @apply rounded-l-lg pl-5;
}
table tbody td:last-of-type {
  @apply rounded-r-lg pr-5;
}
table tbody td.logo {
  @apply pl-2 pr-2 overflow-hidden;
}
table tbody td.logo a {
  @apply flex justify-center items-center;
  height: 100%;
}
table tbody td.logo img {
  max-height: 24px;
  width: auto;
  filter: drop-shadow(0 0 2px rgba(32, 60, 161, 0.25));
}

.skills .have {
  @apply block;
  width: 37px;
}
.skills .have .box {
  @apply border rounded-sm shadow-sm shadow-accent/15;
  height: 25px;
  width: 25px;
}
.skills .have .box.checked {
  @apply shadow-none border-none bg-secondary/50;
}
.skills .name {
  min-width: 200px;
}
.skills .skillCard {
  @apply flex gap-5;
}
.skills .skillCard svg {
  margin: 0;
}
.skills .wideList {
  margin-top: 0.5rem;
}

input,
textarea {
  @apply w-full p-3 rounded-lg border border-secondary/50 mb-5;
}
input:focus-visible,
textarea:focus-visible {
  @apply outline-primary/75 outline;
}

textarea {
  min-height: 250px;
}
textarea#location {
  min-height: 64px;
}

fieldset {
  @apply border border-secondary px-5 rounded-lg;
}
fieldset legend {
  @apply p-2 bg-base-100 text-secondary;
  font-size: 1.15rem;
  font-family: "Michroma";
}
fieldset .sourceBox {
  width: 15%;
}

form {
  @apply w-full;
}
form p {
  @apply text-secondary px-5 py-2;
}
form .formLine {
  @apply flex gap-5;
}
form .formLine label {
  @apply grow;
}
form .formLine label.narrow {
  @apply grow-0 w-1/4;
}

body {
  font-family: "Robo";
}

h1,
h2,
h3 {
  font-family: "Michroma";
}

nav.sidePanel {
  @apply text-base-100 fixed h-screen bg-gradient-to-b from-secondary to-primary;
  font-family: "Michroma";
  border-radius: 0 50px 50px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 300px;
}
nav.sidePanel h1 {
  @apply border-b-8 border-base-100;
  font-size: 1.5rem;
  text-align: center;
  padding: 2rem 3rem 2rem;
}
nav.sidePanel .links {
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
nav.sidePanel .links .linkWrap,
nav.sidePanel .links .linkGroup {
  display: flex;
  gap: 1rem;
}
nav.sidePanel .links .subLinkWrap {
  @apply border-base-100 border-t-2 before:border-base-100 before:border-b-2 before:border-l-2;
}
nav.sidePanel .links .subLinkWrap svg {
  display: none;
}
nav.sidePanel .links .linkWrap {
  padding: 0.5rem 3rem;
}
nav.sidePanel .links .linkGroup {
  flex-direction: column;
  gap: 0;
}
nav.sidePanel .links .subLinkWrap {
  font-size: 0.75rem;
  display: flex;
  margin-left: 3.25rem;
  padding: 10px 10px 10px 0;
}
nav.sidePanel .links .subLinkWrap::before {
  height: 1.25rem;
  width: 1rem;
  margin-top: -10px;
  content: "";
  display: block;
  margin-right: 0.5rem;
}
nav.sidePanel .sideBottom {
  margin: auto auto 1.5rem auto;
  text-align: center;
}

.page {
  margin-left: 300px;
  padding: 2rem;
}
.page .autoSaveSpinner {
  position: absolute;
  z-index: 999;
  right: 10px;
  top: 10px;
}
.page h1 {
  @apply text-primary;
  padding-left: 2rem;
}
.page h2 {
  @apply text-secondary;
  font-size: 1.5rem;
}
.page a {
  @apply text-secondary;
}
.page a svg {
  margin-right: 0.5rem;
  margin-top: 1rem;
}
.page .header {
  margin-bottom: 1rem;
}
.page .header h1 {
  padding: 0;
  font-size: 3rem;
}
.page .header img {
  height: 75px;
  width: auto;
}
.page .header .linkButtons a {
  @apply text-white;
}
.page.listing .header {
  margin-bottom: 0.5rem;
}
.page.listing .underHeader svg {
  @apply text-secondary/50;
}
.page.notes .noteBox {
  background-color: transparent;
}
.page.notes .noteBox .date {
  border-radius: 0;
}
.page.notes .noteBox .textBlock {
  max-width: 100%;
}
.page.notes .noteBox p:last-of-type {
  margin-bottom: 0;
}
.page.notes .note {
  @apply border border-secondary rounded-lg mb-5;
}
.page.notes .addNote {
  position: relative;
}
.page.notes .addNote .iconWrap {
  @apply flex justify-center items-center;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}
.page .spinnerWrap {
  @apply bg-secondary/40;
  position: absolute;
  display: flex;
  justify-content: center;
  padding-top: 15%;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
}
.page .col {
  width: calc(50% - 1rem);
}

.side.notes {
  max-width: 700px;
}
.side input[type=checkbox] {
  border: none;
  margin-bottom: 0;
}
.side .collapse-arrow > .collapse-title:after {
  opacity: 0.35;
}

.textBlock {
  padding: 1rem 0;
  max-width: 700px;
}
.textBlock p {
  margin-bottom: 1rem;
}
.textBlock ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.map,
.noMap {
  @apply shadow-lg shadow-accent/15;
  width: calc(100% + 4rem);
  margin-left: -2rem;
  margin-bottom: 2rem;
  height: 250px;
  background-color: rgba(13, 69, 104, 0.2);
}

.noMap {
  @apply flex items-center justify-center;
}
.noMap h2 {
  @apply text-neutral/50;
}

.newCompanyPage .logo {
  height: 200px;
  width: 200px;
  border-radius: 20px;
  overflow: hidden;
}

.companyCard {
  @apply bg-white w-full p-5 rounded-xl shadow-sm shadow-accent/15 text-black/60 relative;
}

.btn {
  font-family: "Michroma";
}

.btn-secondary {
  @apply text-base-100;
}

.autoComplete {
  position: relative;
  z-index: 5;
}
.autoComplete input,
.autoComplete label {
  position: relative;
  z-index: 1;
}
.autoComplete .box {
  @apply w-full bg-white absolute shadow-lg shadow-accent/15 flex flex-col gap-2 text-secondary;
  top: calc(100% - 1.25rem + 3px);
  z-index: 995;
}
.autoComplete .box span {
  @apply px-5 py-2 hover:bg-base-100;
  z-index: 999;
}

.details {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
.details .pale,
.details .dimmed {
  display: flex;
  gap: 10px;
  align-items: center;
}
.details .pale .icon,
.details .dimmed .icon {
  @apply text-primary/65;
  display: flex;
  justify-content: center;
  align-items: center;
}
.details .pale {
  @apply text-primary/50;
}
.details .dimmed {
  @apply text-neutral/40;
}

.skillsList {
  padding: 1rem 0;
}
.skillsList .skill {
  @apply bg-secondary/15 px-4 py-2 rounded-md text-secondary;
}

.spinner {
  @apply border-t-primary/50 border-x-primary/50 border-b-transparent;
  height: 50px;
  width: 50px;
  border-radius: 1000px;
  border-style: solid;
  border-width: 10px;
  animation: rotation 2s infinite linear;
}
.spinner.small {
  height: 25px;
  width: 25px;
  border-width: 7px;
}
.spinner.large {
  height: 100px;
  width: 100px;
  border-width: 20px;
}

.notes .noteHeader {
  font-size: 2.5rem;
}
.notes .noteBox {
  @apply bg-secondary/15 rounded-xl mb-6 p-5;
  overflow: hidden;
}
.notes .noteBox .date {
  @apply bg-secondary/15 font-bold text-secondary py-2 px-5 text-right rounded-full;
}
.notes .noteBox .noteBody {
  @apply flex flex-col gap-2 my-6 px-8;
}

.Toastify .Toastify__toast--error svg {
  fill: #fa4c76;
}

.fade {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.vShrink {
  margin-top: -9%;
  transition: margin 0.3s ease-out 0.3s;
}

/* Always set the map height explicitly to define the size of the div
 * element that contains the map. */
gmp-map {
  height: 100%;
}

/* Optional: Makes the sample page fill the window. */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.Modal {
  @apply bg-base-100 p-10 rounded-xl shadow-lg shadow-accent/50;
  position: absolute;
  top: 60px;
  left: 60px;
  right: 60px;
  border: none;
  outline: none;
}
.Modal input {
  margin-bottom: 0;
}
.Modal .loaderBox {
  width: 30px;
}
.Modal .close {
  @apply shadow-sm shadow-accent/75;
  position: absolute;
  top: -10px;
  right: -10px;
}
.Modal h1 {
  @apply text-primary;
  font-size: 2rem;
  font-family: "Michroma";
}
.Modal .swap input[type=checkbox] {
  border: none;
  margin-bottom: 0;
}
.Modal .swap .swap-option {
  @apply p-3 rounded-lg flex justify-center items-center;
  min-width: 100px;
  min-height: 52px;
}
.Modal label span {
  font-family: "Michroma";
  font-size: 1.15rem;
  font-weight: bold;
}
.Modal .formLine {
  margin-bottom: 2rem;
  gap: 2.5rem;
}
.Modal .bottomLine {
  @apply flex justify-between items-center;
}
.Modal .bottomLine .archive .btn {
  @apply flex justify-center items-center;
  width: 200px;
}

.page.dashboard {
  min-height: 100vh;
}
.page.dashboard a,
.page.dashboard a h3 {
  @apply w-full flex gap-4 items-center;
}
.page.dashboard a svg,
.page.dashboard a h3 svg {
  margin: 0;
}
.page.dashboard a h3 {
  @apply text-primary;
}

.dashCard {
  @apply bg-white shadow-sm shadow-accent/15 p-5 rounded-lg;
}

.dashCol {
  padding-bottom: 2rem;
}
.dashCol h3 {
  margin-bottom: 1rem;
}
.dashCol .spinnerCol {
  @apply w-full h-full flex justify-center items-center bg-secondary/15 rounded-lg;
}

.Overlay {
  @apply bg-secondary/50;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hideText {
  opacity: 0;
  transition: opacity 0.25s ease-in;
}

.showText {
  opacity: 1 !important;
  transition: opacity 0.25s ease-out;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@media (max-width: 1540px) {
  .formCols {
    flex-direction: column;
  }
  .formCols .col {
    width: 100%;
  }
  .formCols .largeFields textarea {
    min-height: 200px;
  }
}
@media (max-width: 1000px) {
  .Modal .formLine {
    flex-direction: column;
    gap: 1rem;
  }
}
@media (max-width: 900px) {
  nav.sidePanel {
    @apply sticky w-full h-min flex flex-row bg-gradient-to-r;
    border-radius: 0;
  }
  nav.sidePanel h1 {
    @apply border-b-0 text-nowrap;
    margin: 1rem auto 1rem 3rem;
    padding: 0.25rem 0 0;
  }
  nav.sidePanel .links {
    @apply flex flex-row;
    margin: 1rem;
  }
  nav.sidePanel .links .linkWrap {
    padding: 0.5rem;
  }
  nav.sidePanel .links span {
    display: none;
  }
  nav.sidePanel .links .linkGroup {
    @apply flex flex-row gap-3 items-center;
  }
  nav.sidePanel .links .subLinkWrap {
    border: 0;
    margin-left: 0;
  }
  nav.sidePanel .links .subLinkWrap svg {
    display: block;
  }
  nav.sidePanel .links .subLinkWrap::before {
    display: none;
  }
  nav.sidePanel .sideBottom {
    display: none;
  }
  .page {
    margin-left: 0;
  }
}
@media (max-width: 745px) {
  nav.sidePanel h1 {
    display: none;
  }
  nav.sidePanel .links {
    @apply w-full justify-between;
    flex-wrap: wrap;
  }
  nav.sidePanel .links .linkWrap {
    padding: 0;
  }
  nav.sidePanel .links .subLinkWrap {
    padding: 0;
  }
}
@media (max-width: 628px) {
  .page .header h1 {
    font-size: 2rem;
  }
  .details {
    display: block;
    margin-top: 1rem;
  }
  .underHeader {
    margin-left: 0 !important;
    gap: 10px !important;
  }
  .listing .dates {
    display: block;
  }
  .listing .dates svg {
    display: none;
  }
  .Modal {
    top: 10px;
    left: 10px;
    right: 10px;
  }
  .Modal .close {
    top: 15px;
    right: 15px;
  }
  .Modal .bottomLine {
    justify-content: space-between;
  }
  .Modal .bottomLine .archive {
    width: 100%;
  }
  .Modal .bottomLine .archive .btn {
    margin-left: auto;
  }
  .Modal .bottomLine .bottomInfo {
    display: none;
  }
}
@media (max-width: 435px) {
  nav.sidePanel .links {
    @apply justify-center;
  }
}

