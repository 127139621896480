@font-face {
    font-family: 'Michroma';
    src: url(../fonts/Michroma-Regular.ttf);
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Robo';
    src: url(../fonts/Roboto-Thin.ttf);
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Robo';
    src: url(../fonts/Roboto-ThinItalic.ttf);
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Robo';
    src: url(../fonts/Roboto-Light.ttf);
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Robo';
    src: url(../fonts/Roboto-LightItalic.ttf);
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Robo';
    src: url(../fonts/Roboto-Regular.ttf);
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Robo';
    src: url(../fonts/Roboto-Italic.ttf);
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Robo';
    src: url(../fonts/Roboto-Medium.ttf);
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Robo';
    src: url(../fonts/Roboto-MediumItalic.ttf);
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Robo';
    src: url(../fonts/Roboto-Bold.ttf);
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Robo';
    src: url(../fonts/Roboto-BoldItalic.ttf);
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Robo';
    src: url(../fonts/Roboto-Black.ttf);
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Robo';
    src: url(../fonts/Roboto-BlackItalic.ttf);
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}